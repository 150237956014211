import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import checkCnicAndMobile from "./SwalHelper";
import Navbar from "../Navbar/navbar";
import Admin from "../Admin/Admin";
import PDF from "../pdfform/Pdf";
import MainForm from "./MainForm/MainForm";

function Landing() {
  const navigate = useNavigate();

  const [news, setNews] = useState([
    "Attention Karachi Region Students: Those who missed their interviews can now appear at IBA University Main Campus, University of Karachi. Please bring all required documents and a copy of the Scholarship Form. Don’t miss this opportunity! Dated: 10th December, 2024, Timings 11:00 AM to 1:30 PM ",
    "کراچی ریجن کے طلباء کے لئے اطلاع: جو طلباء کسی بھی وجہ سے انٹرویو میں شامل نہیں ہو سکے، وہ اب آئی بی اے یونیورسٹی مین کیمپس، جامعہ کراچی میں انٹرویو دے سکتے ہیں۔ تمام مطلوبہ دستاویزات اور اسکالرشپ فارم کی کاپی ساتھ لائیں۔ اس موقع کو ضائع نہ کریں!"






,
  ]);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const [isLtr, setIsLtr] = useState(false);

  // Cycle through news items and change text after specific time
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % news.length);
    }, currentNewsIndex === 0 ? 15000 : 15000); // Adjust duration: 38s for English, 20s for Urdu

    return () => clearInterval(interval);
  }, [currentNewsIndex]);

  // Determine text direction (RTL for Urdu, LTR for English)
  useEffect(() => {
    const currentNews = news[currentNewsIndex];
    const isUrdu = /[\u0600-\u06FF]/.test(currentNews); // Check for Urdu characters
    setIsLtr(isUrdu);
  }, [currentNewsIndex, news]);


  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "Affidavit.pdf";
    link.download = "Sample-Affidavit-PDF";
    link.target = "_blank";
    link.rel = "noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleApply = () => {
    navigate("/form");
  };

  const openPopupWithPdf = (pdfUrl) => {
    const popup = window.open(
      "",
      "_blank",
      "width=800,height=600,top=100,left=100,resizable=yes"
    );

    popup.document.write(`
      <html>
        <head>
          <title>PDF Preview</title>
          <style>
            body { margin: 0; }
            iframe {
              width: 100%;
              height: 100%;
              border: none;
            }
          </style>
        </head>
        <body>
          <iframe src="${pdfUrl}" frameborder="0"></iframe>
        </body>
      </html>
    `);
  };

  const handleView = () => {
    openPopupWithPdf("/Interview Schedule.pdf");
  };

  const handleList = () => {
    openPopupWithPdf("/interview list 2024.pdf");
  };

  const handleRevisedSchedule = () => {
    openPopupWithPdf("/revised-interview-schedule.pdf");
  };

  const handleUploadResult = async () => {
    try {
      const data = await checkCnicAndMobile();
      console.log("Data:", data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleCheckCnic = () => {
    Swal.fire({
      title: "Check CNIC and Mobile Number",
      html: `
        <input type="text" id="cnic" class="swal2-input" placeholder="CNIC (e.g. 12345-6789123-4)">
        <input type="text" id="mobile" class="swal2-input" placeholder="Mobile Number (e.g. 0300-1234567)">
      `,
      showCancelButton: true,
      confirmButtonText: "Verify",
      cancelButtonText: "Cancel",
      preConfirm: () => {
        const cnic = document.getElementById("cnic").value;
        const mobile = document.getElementById("mobile").value;
        const cnicPattern = /^\d{5}-\d{7}-\d$/;
        const mobilePattern = /^\d{4}-\d{7}$/;

        if (!cnicPattern.test(cnic) || !mobilePattern.test(mobile)) {
          Swal.showValidationMessage("Invalid CNIC or Mobile Number format");
          return;
        }

        return { cnic, mobile };
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        const { cnic, mobile } = result.value;
        axios
          .post("http://localhost:8000/api/check-cnic-and-mobile", {
            cnic,
            mobile,
          })
          .then((res) => {
            if (res.data.data) {
              const fetchedData = res.data.data;
              Swal.fire({
                title: "CNIC and Mobile Number Verified",
                text: `The entered CNIC and Mobile Number combination exists in the database with ID: ${fetchedData.id}`,
                icon: "success",
                confirmButtonText: "OK",
              });
            } else {
              Swal.fire({
                title: "CNIC and Mobile Number Not Found",
                text: "The entered CNIC and Mobile Number combination does not exist in the database",
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((err) => {
            console.log(
              "An error occurred while checking the CNIC and Mobile Number:",
              err
            );
          });
      }
    });
  };

  const handleTutorial = () => {
    const videoUrl = "https://www.youtube.com/watch?v=uGfmaTNa2qM";
    const windowFeatures =
      "width=800,height=600,top=100,left=100,resizable=yes";
    window.open(videoUrl, "_blank", windowFeatures);
  };

  return (
    <>
      <div className="min-h-screen flex flex-col justify-center items-center p-4 bg-gray-100">
        <header className="flex flex-col lg:flex-row justify-between items-center mb-4">
          <div className="w-20 mb-4 lg:mb-0 lg:mr-4">
            <img src="/logo.png" alt="Logo" className="w-full" />
          </div>
          <div className="text-center lg:ml-10">
            <h2 className="font-bold text-lg">GOVERNMENT OF SINDH</h2>
            <p className="text-base">COLLEGE EDUCATION DEPARTMENT</p>
            <p className="text-base">SINDH EDUCATIONAL ENDOWMENT FUND</p>
            <p className="text-base">REGISTERED VIDE TRUST DEED NO. 625/2020</p>
          </div>
        </header>

        {/* News Ticker */}
        {/* <div className="w-11/12 bg-blue-500 text-white py-2 overflow-hidden relative">
        <div
          className={`whitespace-nowrap font-semibold text-2xl ${
            isLtr ? "animate-marquee-ltr" : "animate-marquee-rtl"
          }`}
          key={currentNewsIndex}
        >
          {news[currentNewsIndex]}
        </div>
      </div> */}
       <h1 className="text-center text-2xl lg:text-xl font-bold mb-5">
      Welcome To <br /> Sindh Educational Endowment Fund <br />
      Scholarship Program
    </h1>
<div className="flex flex-col lg:flex-row items-start justify-center p-8 bg-gray-100 min-h-screen">
  {/* News Ticker Section */}
  {/* <div className="w-full lg:w-4/12 bg-blue-100 text-slate-700 py-4 px-9 rounded-lg shadow-lg lg:mr-4">
  <h2 className="text-center font-bold underline mb-4">Latest News</h2>
  <div className="overflow-hidden relative h-96">
    <ul
      className="absolute space-y-4 animate-scroll-news"
      style={{ animationDuration: `${news.length * 10}s` }}
    >
      {news.map((item, index) => (
        <li
          key={index}
          className={`font-medium text-lg ${
            /[\u0600-\u06FF]/.test(item) ? "text-right" : "text-left"
          }`}
        >
          {item}
        </li>
      ))}
    </ul>
  </div>
</div> */}

  {/* Main Content Section */}
  <div className="w-full lg:w-6/12 p-10 bg-gray-300 rounded-lg shadow-lg mt-4 lg:mt-0">
   
    <div>
      <button
        onClick={handleDownload}
        className="w-full mb-6 py-2 px-4 text-sm lg:text-base bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Download Sample Affidavit
      </button>
      <button
        onClick={handleView}
        className="w-full mb-6 py-2 px-4 text-sm lg:text-base bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Preview Interview Schedule
      </button>
      <button
        onClick={handleRevisedSchedule}
        className="w-full mb-6 py-2 px-4 text-sm lg:text-base bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Preview Revised Interview Schedule
      </button>
      <button
        onClick={handleList}
        className="w-full mb-6 py-2 px-4 text-sm lg:text-base bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Preview Interview List 2024
      </button>

      <PDF />
      <div className="mt-3">
        <h2 className="text-center font-bold underline text-lg">Contact Us:</h2>
        <p className="text-sm font-bold">
          For any queries, please contact{" "}
          <span className="underline text-blue-800">Mr. Usman Ali Mangrio</span>{" "}
          at <span className="underline text-blue-800">0345-2132045</span>
        </p>
      </div>
    </div>
  </div>
</div>
  </div>
    </>
  );
}

export default Landing;
